import { withPasswordProtect } from "next-password-protect"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import PiwikProProvider from "@piwikpro/next-piwik-pro"
import { Analytics } from "@vercel/analytics/react"
import { AnimatePresence } from "framer-motion"

import { ProgressBar } from "@components/progressbar"

import "../styles/globals.css"

import invariant from "tiny-invariant"

function MyApp({ Component, pageProps }: AppProps) {
  invariant(
    process.env.NEXT_PUBLIC_PIWIK_CONTAINER_ID,
    "NEXT_PUBLIC_PIWIK_CONTAINER_ID is undefined"
  )
  invariant(
    process.env.NEXT_PUBLIC_PIWIK_CONTAINER_URL,
    "NEXT_PUBLIC_PIWIK_CONTAINER_URL is undefined"
  )

  const [isAnimating, setIsAnimating] = useState(false)
  const router = useRouter()

  // add custom route change animation
  useEffect(() => {
    const handleStart = () => setIsAnimating(true)
    const handleStop = () => setIsAnimating(false)

    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleStop)
    router.events.on("routeChangeError", handleStop)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleStop)
      router.events.off("routeChangeError", handleStop)
    }
  }, [router])

  return (
    <>
      <PiwikProProvider
        containerId={process.env.NEXT_PUBLIC_PIWIK_CONTAINER_ID}
        containerUrl={process.env.NEXT_PUBLIC_PIWIK_CONTAINER_URL}
        nonce="nonce-string"
      >
        <AnimatePresence initial={false}>
          <ProgressBar isAnimating={isAnimating} />
          <Component key={(Component as any).animateKey} {...pageProps} />
          <Analytics />
        </AnimatePresence>
      </PiwikProProvider>
    </>
  )
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginApiUrl: "/api/beta-login",
      checkApiUrl: "/api/beta-password-check",
    })
  : MyApp
